<template>
  <v-container fluid class="no-indentation mb-12">
      <v-row class="no-indentation mt-lg-12 mt-md-12 mt-sm-10 px-lg-12 px-md-0">
          <about-company-submenu :toggle_none="this.toggle_exclusive"></about-company-submenu>
      </v-row>
      <v-row class="no-indentation px-lg-12 px-md-0">
          <v-col cols="12" lg="12" md="12" sm="12" class="mt-0 pr-6 pr-sm-6 pr-md-0 pr-lg-0">
              <h1 class="contentHeading">{{ $t('message.aboutUsSubmenu2') }}</h1>
              <h1 class="contentSubTitle">{{ $t('message.aboutUsSubTitle1') }}</h1>
              <content-documents :content="$t('message.aboutUsDocument1')" :href="$t('message.aboutUsDocument1HREF')"/>
              <content-documents :content="$t('message.aboutUsDocument3')" :href="$t('message.aboutUsDocument3HREF')"/>
              <h1 class="contentSubTitle">{{ $t('message.aboutUsSubTitle2') }}</h1>
              <content-documents :content="$t('message.aboutUsDocument2')" :href="$t('message.aboutUsDocument2HREF')"/>
              <h1 class="contentSubTitle">{{ $t('message.aboutUsSubTitle3') }}</h1>
<!--              <content-documents content='!!!Уведомление органов статистики' href="#"/>-->
              <content-documents :content="$t('message.aboutUsDocument5')" :href="$t('message.aboutUsDocument5HREF')"/>
              <content-documents :content="$t('message.aboutUsDocument7')" :href="$t('message.aboutUsDocument7HREF')"/>
              <content-documents :content="$t('message.aboutUsDocument9')" :href="$t('message.aboutUsDocument9HREF')"/>
          </v-col>
      </v-row>
  </v-container>
</template>
<script>
import '../assets/sass/app.scss'
import '../plugins/i18n.js'
import AboutCompanySubmenu from '../components/ui/AboutCompanySubmenu'
import ContentDocuments from '../components/ui/ContentDocuments'
export default {
  components: { AboutCompanySubmenu, ContentDocuments },
  data () {
    return {
      toggle_exclusive: 1
    }
  }
}
</script>
