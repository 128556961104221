<template>
  <v-container fluid class="no-indentation" style="margin-bottom: 96px!important;">
      <v-row class="no-indentation mt-lg-12 mt-md-12 mt-sm-10 px-lg-12 px-md-0">
          <about-company-submenu :toggle_none="this.toggle_exclusive"/>
      </v-row>
      <v-row class="no-indentation px-lg-12 px-md-0">
          <v-col cols="12" lg="4" md="4" sm="12" class="colManagement mt-0 pr-6 pr-sm-6 pr-md-0 pr-lg-0">
              <content-text :title="$t('message.aboutUsSubmenu1')"/>
              <card-management :pathImg="this.avatar1"
                               :fio="$t('message.aboutUsManagementFIO1')"
                               :post="$t('message.aboutUsManagementPost1')"
              />
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="12" class="colManagement pr-6 pr-sm-6 pr-md-0 pr-lg-0 colTopManagement">
              <card-management :pathImg="this.avatar3"
                               :fio="$t('message.aboutUsManagementFIO3')"
                               :post="$t('message.aboutUsManagementPost3')"
              />
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="12" class="colManagement pr-6 pr-sm-6 pr-md-0 pr-lg-0 colTopManagement">
                <card-management :pathImg="this.avatar8"
                                 :fio="$t('message.aboutUsManagementFIO4')"
                                 :post="$t('message.aboutUsManagementPost4')"
                />
          </v-col>
      </v-row>
      <v-row class="no-indentation px-lg-12 px-md-0">
          <v-col cols="12" lg="4" md="4" sm="12" class="colManagement pr-6 pr-sm-6 pr-md-0 pr-lg-0">
              <card-management :pathImg="this.avatarMiss"
                               :fio="$t('message.aboutUsManagementFIO5')"
                               :post="$t('message.aboutUsManagementPost5')"
              />
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="12" class="colManagement">
              <card-management :pathImg="this.avatar6"
                               :fio="$t('message.aboutUsManagementFIO6')"
                               :post="$t('message.aboutUsManagementPost6')"
              />
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="12" class="colManagement pr-6 pr-sm-6 pr-md-0 pr-lg-0">
              <card-management :pathImg="this.avatar7"
                               :fio="$t('message.aboutUsManagementFIO7')"
                               :post="$t('message.aboutUsManagementPost7')"
            />
          </v-col>
      </v-row>
  </v-container>
</template>
<script>
import '../assets/sass/app.scss'
import '../plugins/i18n.js'
import AboutCompanySubmenu from '../components/ui/AboutCompanySubmenu'
import CardManagement from '../components/ui/CardManagement'
export default {
  components: { AboutCompanySubmenu, CardManagement },
  data () {
    return {
      toggle_exclusive: 0,
      avatar1: './img/avatar/RadgovskayaEV.jpg',
      avatar2: './img/avatar/Karpov.jpg',
      avatar3: './img/avatar/Mischenkov.jpg',
      avatar4: './img/avatar/Nazarov.jpg',
      avatar5: './img/avatar/Petrov.jpg',
      avatar6: './img/avatar/Prohorenkova.jpg',
      avatar7: './img/avatar/Kondrashov.jpg',
      avatar8: './img/avatar/Sitnikov.jpeg',
      avatarMiss: './img/avatar/business-man.png',
    }
  }
}
</script>
