<template>
  <v-container fluid class="no-indentation mb-12">
      <v-row class="no-indentation mt-lg-12 mt-md-12 mt-sm-10 px-lg-12 px-md-0">
          <partnership-submenu :toggle_none="this.toggle_exclusive"></partnership-submenu>
      </v-row>
      <v-row class="no-indentation px-lg-12 px-md-0">
          <v-col cols="12" lg="5" md="5" sm="11">
              <content-text :title="$t('message.partnershipSubmenu1')" :content="$t('message.partnershipText2')"></content-text>
              <div class="contentText">{{ $t('message.partnershipText3') }}</div>
              <div class="contentText">{{ $t('message.partnershipText4') }}</div>
              <div class="contentText">{{ $t('message.partnershipText5') }}</div>
              <div class="contentText">{{ $t('message.partnershipText6') }}</div>
              <div class="contentText">{{ $t('message.partnershipText7') }}</div>
              <div class="contentText">{{ $t('message.partnershipText8') }}</div>
              <div class="contentText">{{ $t('message.partnershipText9') }}</div>
              <h1 class="contentSubTitle">{{ $t('message.partnershipLogisticsSubHead1') }} </h1>
              <div class="contentText">{{ $t('message.aboutUsText3') }}</div>
          </v-col>
         <v-col cols="12" lg="6" md="6" sm="11" offset-lg="1" offset-md="1" offset-sm="0">
              <v-row class="no-indentation ml-0">
                  <v-col cols="12" lg="6" md="6" sm="12" class="mt-0 pt-0">
                      <h1 class="partnershipSubHeading">{{ $t('message.partnershipLogisticsNumericHead') }}</h1>
                      <content-numeric :unit="$t('message.contentNum7')"
                                       :content="$t('message.partnershipNumeric1')"
                      />
                      <content-numeric num="100 +"
                                       :content="$t('message.partnershipNumeric2')"
                      />
                      <content-numeric :unit="$t('message.contentNum8')"
                                       :content="$t('message.partnershipNumeric4')"
                      />
                  </v-col>
                   <v-col cols="12" lg="6" md="6" sm="12" class="mt-0 pt-0">
                       <h1 class="partnershipSubHeading">{{ $t('message.partnershipWarehouseNumericHead') }}</h1>
                      <content-numeric :subtitle="$t('message.aboutUsSubHeading')"
                                       num="50 +"
                                       :content="$t('message.contentNumeric7')"
                      />
                      <content-numeric num="10000 +"
                                       :content="$t('message.partnershipNumeric7')"
                      />
                      <content-numeric :num="$t('message.partnershipNumericNum1')"
                                       :content="$t('message.partnershipNumeric8')"
                      />
                  </v-col>
              </v-row>
          </v-col>
      </v-row>
  </v-container>
</template>
<script>
import '../assets/sass/app.scss'
import '../plugins/i18n.js'
import PartnershipSubmenu from '../components/ui/PartnershipSubmenu'
export default {
  components: { PartnershipSubmenu },
  data () {
    return {
      toggle_exclusive: 0
    }
  }
}
</script>
