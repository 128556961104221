<template>
  <v-container fluid class="no-indentation mb-12">
      <v-row class="no-indentation mt-lg-12 mt-md-12 mt-sm-10 px-lg-12 px-md-0">
          <partnership-submenu :toggle_none="this.toggle_exclusive"/>
      </v-row>
      <v-row class="no-indentation px-lg-12 px-md-0">
          <v-col cols="12" lg="12" md="12" sm="12">
              <content-text :title="$t('message.partnershipSubmenu3')"/>
          </v-col>
      </v-row>
      <v-row class="no-indentation px-lg-12 px-md-0">
        <v-col cols="12" lg="3" md="4" sm="6">
            <card-logo path-img="/img/brand/AND.png" href-partner="https://aandd.ru/"/>
        </v-col>
        <v-col cols="12" lg="3" md="4" sm="6">
              <card-logo path-img="/img/brand/BWell.png" href-partner="https://bwell-swiss.ru/"/>
          </v-col>
        <v-col cols="12" lg="3" md="4" sm="6">
              <card-logo path-img="/img/brand/Omron.png" href-partner="https://omron.ru/ru/home"/>
          </v-col>
        <v-col cols="12" lg="3" md="4" sm="6">
              <card-logo path-img="/img/brand/SMP.jpeg" href-partner="https://smp67.com/about_company"/>
          </v-col>
      </v-row>
  </v-container>
</template>
<script>
import '../assets/sass/app.scss'
import '../plugins/i18n.js'
import CardLogo from '../components/ui/CardLogo'
import { mdiChevronRight } from '@mdi/js'
export default {
  components: { CardLogo },
  data () {
    return {
      svgAllNewsBtnPath: mdiChevronRight,
      toggle_exclusive: 2
    }
  }
}
</script>
