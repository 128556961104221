<template>
  <v-container fluid class="no-indentation mb-12">
      <v-row class="no-indentation mt-lg-12 mt-md-12 mt-sm-10 px-lg-12 px-md-0">
          <partnership-submenu :toggle_none="this.toggle_none"></partnership-submenu>
      </v-row>
      <v-row class="no-indentation px-lg-12 px-md-0">
          <v-col cols="12" lg="6" md="6" sm="12">
              <content-text :title="$t('message.partnershipHead')" :content="$t('message.sliderText1')"></content-text>
              <h1 class="contentSubTitle">{{ $t('message.partnershipSubHead1') }} </h1>
              <div class="contentText">{{ $t('message.partnershipText1') }}</div>
              <div class="partnershipAdvantage pa-0">
                  <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Acb9296bd611508a514297415367fcbc232653c91e28ea340fe77941465e63bbd&amp;source=constructor"></iframe>
              </div>
          </v-col>
          <v-col cols="12" lg="5" md="5" sm="12" offset-lg="1" offset-md="1" offset-sm="0">
              <h1 class="partnershipSubHeading pl-lg-1 pl-md-3 pl-sm-4 pl-4">{{ $t('message.partnershipSubHead2') }}</h1>
              <div class="contentText pt-4">
                  <a :href="$t('message.partnershipSchemeSRC')" target="_blank">
                      <img :src="$t('message.partnershipSchemeSRC')" width="95%"/>
                  </a>
              </div>
          </v-col>
      </v-row>
  </v-container>
</template>
<script>
import '../assets/sass/app.scss'
import '../plugins/i18n.js'
import PartnershipSubmenu from '../components/ui/PartnershipSubmenu'
export default {
  components: { PartnershipSubmenu },
  data () {
    return {
      toggle_none: undefined
    }
  }
}</script>