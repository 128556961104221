<template>
  <v-container fluid class="no-indentation mb-12">
      <v-row class="no-indentation mt-lg-12 mt-md-12 mt-sm-10 px-lg-12 px-md-0">
          <partnership-submenu :toggle_none="this.toggle_exclusive"/>
      </v-row>
      <v-row class="no-indentation px-lg-12 px-md-0">
          <v-col cols="12" lg="5" md="5" sm="11">
              <content-text :title="$t('message.partnershipSubHead3')"/>
              <div class="contentText mt-0">
                  {{ $t('message.partnershipText11') }}
              </div>
              <div class="contentText">
                  {{ $t('message.partnershipText12') }}
              </div>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="11" offset-lg="1" offset-md="1" offset-sm="0">
              <h1 class="partnershipSubTitle">{{ $t('message.partnershipSubHead4') }} </h1>
              <div class="contentTextSuppliers contentAnticorruption">
                  <ul>
                      <li class="mb-4">
                          <span>{{ $t('message.partnershipText15') }}</span>
                      </li>
                      <li class="mb-4">
                          <span>{{ $t('message.partnershipText16') }}</span>
                      </li>
                      <li class="mb-4">
                          <span>{{ $t('message.partnershipText17') }}</span>
                      </li>
                      <li class="mb-4">
                          <span>{{ $t('message.partnershipText18') }}</span>
                      </li>
                      <li class="mb-4">
                          <span>{{ $t('message.partnershipText24') }}</span>
                      </li>
                      <li class="mb-4">
                          <span>{{ $t('message.partnershipText25') }}</span>
                      </li>
                      <li class="mb-4">
                          <span>{{ $t('message.partnershipText26') }}</span>
                      </li>
                      <li class="mb-4">
                          <span>{{ $t('message.partnershipText27') }}</span>
                      </li>
                  </ul>
              </div>
              <div class="contentTextSuppliers contentAnticorruption">
                 {{ $t('message.partnershipText20') }}: <a href="mailto:ao-ti.info@mail.ru" target="_blank">ao-ti.info@mail.ru</a>
                  {{ $t('message.partnershipText21') }} <a href="tel:+7(930)305-87-00">+7(930)305-87-00</a>
              </div>
              <div class="contentTextSuppliers contentAnticorruption">
                 {{ $t('message.partnershipText28') }}
              </div>
          </v-col>
      </v-row>
      <v-row class="no-indentation px-lg-12 px-md-0">
          <v-col cols="12" lg="5" md="9" sm="11">
              <h1 class="contentSubTitle mt-6">{{ $t('message.partnershipSubHead5') }} </h1>
              <div class="contentText">
                  {{ $t('message.partnershipText22') }}:
                  <offer-goods-form class="ml-0 d-block d-sm-block d-md-none d-lg-none"/>
              </div>
          </v-col>
      </v-row>
      <v-row class="no-indentation px-lg-12 px-md-0">
          <v-col cols="12" lg="6" md="10" sm="11">
              <offer-goods-form class="mt-0 d-none d-sm-none d-md-block d-lg-block"/>
          </v-col>
      </v-row>
  </v-container>
</template>
<script>
import '../assets/sass/app.scss'
import '../plugins/i18n.js'
import PartnershipSubmenu from '../components/ui/PartnershipSubmenu.vue'
import ContentText from '../components/ui/ContentText'
import ContentNumeric from '../components/ui/ContentNumeric'
import AboutCompanySubmenu from '../components/ui/AboutCompanySubmenu'
import OfferGoodsForm from '../components/ui/OfferGoodsForm'
import ReportForm from '../components/ui/ReportForm'
import { mdiChevronRight } from '@mdi/js'
export default {
  components: { PartnershipSubmenu, ContentText, ContentNumeric, AboutCompanySubmenu, OfferGoodsForm, ReportForm },
  data () {
    return {
      svgAllNewsBtnPath: mdiChevronRight,
      toggle_exclusive: 1
    }
  }
}
</script>
