<template>
  <v-container fluid class="no-indentation mb-12">
      <v-row class="no-indentation mt-lg-12 mt-md-12 mt-sm-10 px-lg-12 px-md-0">
          <partnership-submenu :toggle_none="this.toggle_exclusive"></partnership-submenu>
      </v-row>
      <v-row class="no-indentation px-lg-12 px-md-0">
          <v-col cols="12" lg="5" md="5" sm="11">
              <content-text :title="$t('message.partnershipSubmenu4')" content="Более XXX организаций"></content-text>
          </v-col>
      </v-row>
      <v-row class="no-indentation px-lg-12 px-md-0">
          <v-col cols="12" lg="3" md="4" sm="12">
              <card-logo></card-logo>
          </v-col>
          <v-col cols="12" lg="3" md="4" sm="12">
              <card-logo></card-logo>
          </v-col>
          <v-col cols="12" lg="3" md="4" sm="12">
              <card-logo></card-logo>
          </v-col>
          <v-col cols="12" lg="3" md="4" sm="12">
              <card-logo></card-logo>
          </v-col>
          <v-col cols="12" lg="3" md="4" sm="12">
              <card-logo></card-logo>
          </v-col>
          <v-col cols="12" lg="3" md="4" sm="12">
              <card-logo></card-logo>
          </v-col>
          <v-col cols="12" lg="3" md="4" sm="12">
              <card-logo></card-logo>
          </v-col>
          <v-col cols="12" lg="3" md="4" sm="12">
              <card-logo></card-logo>
          </v-col>
          <v-col cols="12" lg="3" md="4" sm="12">
              <card-logo></card-logo>
          </v-col>
      </v-row>
  </v-container>
</template>
<script>
import '../assets/sass/app.scss'
import '../plugins/i18n.js'
import Submenu from '../components/ui/Submenu'
import ContentText from '../components/ui/ContentText'
import ContentNumeric from '../components/ui/ContentNumeric'
import AboutCompanySubmenu from '../components/ui/AboutCompanySubmenu'
import { mdiChevronRight } from '@mdi/js'
export default {
  components: { Submenu, ContentText, ContentNumeric, AboutCompanySubmenu },
  props: ['submenu'],
  data () {
    return {
      svgAllNewsBtnPath: mdiChevronRight,
      toggle_exclusive: 3
    }
  }
}
</script>
